<template>
  <base-layout-two
    page-title="Request Payment"
    :page-default-back-link="`/users/${this.$route.params.id}/folios/${this.$route.params.folio_id}/`"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Request Payment">
          <p>Requesting a payment will charge a member's credit card.</p>

          <ion-radio-group :value="transactionFor">
            <ion-item>
              <ion-label>Specific Amount</ion-label>
              <ion-radio
                slot="start"
                color="success"
                value="other"
                @click="transactionFor = 'other'"
              ></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label
                >Balance ${{ Math.abs(balance.amount).toFixed(2) }}</ion-label
              >
              <ion-radio
                slot="start"
                color="success"
                value="balance"
                @click="transactionFor = 'balance'"
              ></ion-radio>
            </ion-item>
          </ion-radio-group>

          <ion-grid class="margin-top-48">
            <ion-row>
              <!-- Payment Method -->
              <ion-col size="12">
                <base-input label-text="Payment Method *">
                  <v-field
                    name="payment_method"
                    v-slot="{ field }"
                    v-model="transaction.payment_method"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option
                        v-for="method of payment_methods"
                        :key="method.id"
                        :value="method.id"
                      >
                        {{ method.card.brand }} ending in
                        {{ method.card.last4 }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="payment_method" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Description -->
              <ion-col>
                <base-input
                  label-text="Description (max length is 22 characters)*"
                >
                  <v-field
                    name="description"
                    v-slot="{ field }"
                    v-model="transaction.description"
                    :rules="required"
                  >
                    <ion-input
                      name="description"
                      v-bind="field"
                      placeholder="Head Massage"
                      maxlength="22"
                      type="text"
                      autocapitalize
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message
                  name="description"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Amount -->
              <ion-col>
                <base-input
                  label-text="Amount (This should be a positive number)*"
                >
                  <v-field
                    name="amount"
                    v-slot="{ field }"
                    v-model="transaction.amount"
                    :rules="requiredPositiveNumber"
                  >
                    <ion-input
                      name="amount"
                      v-bind="field"
                      placeholder="0.00"
                      type="number"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message
                  name="amount"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Amount -->
              <ion-col>
                <base-input label-text="Date *">
                  <v-field
                    name="date"
                    v-slot="{ field }"
                    v-model="transaction.date"
                    :rules="required"
                  >
                    <ion-input
                      name="date"
                      v-bind="field"
                      placeholder=""
                      type="date"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message
                  name="amount"
                  class="date-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Memo -->
              <ion-col>
                <base-input label-text="Memo">
                  <v-field
                    name="memo"
                    v-slot="{ field }"
                    v-model="transaction.memo"
                  >
                    <ion-textarea
                      name="memo"
                      :auto-grow="true"
                      rows="1"
                      v-bind="field"
                      placeholder="Internal note about this charge"
                      autocapitalize
                      autocorrect
                    ></ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message
                  name="memo"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Request Payment
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonTextarea,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonTextarea,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonItem,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      required: yup.string().required(),
      requiredPositiveNumber: yup.number().required().positive(),
      transactionFor: "other",
      folio: {
        transactions: [],
      },
      line_items: [],
      payments: [],
      payment_methods: [],
      transaction: {
        user_id: this.$route.params.id,
        type: "charge",
        is_deposit: 0,
        folio_id: this.$route.params.folio_id,
        date: format(new Date(), "yyyy-MM-dd"),
        attempt_charge: 1,
        payment_method: null,
      },
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    subtotal() {
      let subtotal = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_final,
        0
      );

      let transaction = {
        description: "Subtotal",
        amount: Number(subtotal),
      };

      return transaction;
    },

    taxesAndFees() {
      let taxesAndFees = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_taxes,
        0
      );

      let transaction = {
        description: "Taxes & Fees",
        amount: Number(taxesAndFees),
      };

      return transaction;
    },

    total() {
      let total = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_total,
        0
      );

      let transaction = {
        description: "Total",
        amount: Number(total),
      };

      return transaction;
    },

    balance() {
      let paymentsTotal = this.payments.reduce(
        (partial_sum, a) => partial_sum + (a.stripe_charge_amount/100 - a.stripe_refund_amount/100),
        0
      );

      let transaction = {
        description: "Balance",
        amount: paymentsTotal + this.total.amount,
      };

      return transaction;
    },
  },

  watch: {
    transactionFor() {
      if (this.transactionFor === "balance") {
        this.transaction.description = `${this.folio.confirmation} - Balance`;
        this.transaction.amount = Math.abs(this.balance.amount).toFixed(2);
      } else {
        this.transaction.description = `${this.folio.confirmation}`;
        this.transaction.amount = 0;
      }
    },
  },

  async ionViewWillEnter() {
    await this.fetchFolio();
    await this.fetchPaymentMethods();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchFolio() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/folios/${this.$route.params.folio_id}`)
        .then((response) => {
          this.folio = response.data.folio;

          if (this.folio.transactions) {
            this.folio.transactions = this.folio.transactions.sort((a, b) => {
              return new Date(a.date) - new Date(b.date);
            });

            // Get the Line Items
            this.line_items = this.folio.transactions.filter(
              (x) => x.type === "line_item"
            );

            // Get the Transactions that have succeeded
            this.payments = this.folio.transactions.filter((x) => {
              if (
                x.type === "charge" &&
                x.stripe_payment_intent_status === "succeeded"
              )
                return true;
              return false;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchPaymentMethods() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/transactions/stripe/paymentmethods?user_id=${this.$route.params.id}`
        )
        .then((response) => {
          this.payment_methods = response.data.payment_methods.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/transactions`,
        data: this.transaction,
      };

      config.data.created_by_id = this.authUser.id;
      config.data.amount = Number(config.data.amount);

      console.log(config.data);

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.transaction = response.data.transaction;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          // this.loadToast({ message: "Payment Request Created", color: "secondary" });

          this.$router.replace(
            `/users/${this.$route.params.id}/folios/${this.$route.params.folio_id}`
          );
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>